@font-face {
  font-family: "VanCondensed";
  font-style: normal;
  src: url("/fonts/Van Condensed Pro-Regular.otf") format("opentype");
}

@font-face {   
  font-family: "VanCondensedBold";   
  font-style: normal;   
  src: url("/fonts/Van Condensed Pro-Bold.otf") format("opentype"); 
}

body {
  margin: 0;
  padding: 0;
  font-family: "VanCondensed";
}

footer {
  font-size: 0.8em;
  max-height: 6px;
}
